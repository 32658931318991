import React from "react";
import styled from "styled-components";
import { Switch, Redirect, Route } from "react-router";
import { withRouter } from "react-router-dom";
import Editor from "./containers/Editor";
import Marketplace from "./containers/Marketplace";
import Profile from "./containers/Profile";
import Terms from "./components/Terms";
import Analytics from "react-router-ga";
import Privacy from "./components/Privacy/Privacy";
import Impressum from "./components/Impressum/Impressum";
import CacheBuster from "./components/CacheBuster/CacheBuster";
import withPixelChainContract from "containers/PixelChainContractWrapper";
import Header from "components/Header";

const Container = styled.div`
  height: 100%;
  background: linear-gradient(0deg, hsla(234, 19%, 21%, 1) 2%, hsla(273, 21%, 33%, 1) 100%);

  display: flex;
  flex-direction: column;
`;

const debug = window.location.hostname !== "pixelchain.cryptomotors.io";

const App = ({ connectWallet, disconnectWallet, walletAddress }) => {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <Container>
            <Analytics id={debug ? "no-code" : "UA-124177179-2"} debug={debug}>
              <Header connectWallet={connectWallet} disconnectWallet={disconnectWallet} walletAddress={walletAddress} />
              <Switch>
                <Route path="/editor" component={Editor} />
                <Route path="/marketplace" component={Marketplace} />
                <Route
                  path="/profile"
                  component={() => (
                    <Profile
                      connectWallet={connectWallet}
                      disconnectWallet={disconnectWallet}
                      walletAddress={walletAddress}
                    />
                  )}
                />
                <Route path="/terms-and-conditions" component={Terms} />
                <Route path="/privacy-policy" component={Privacy} />
                <Route path="/impressum" component={Impressum} />
                <Route path="/" component={Editor} />
                <Redirect to="/editor" />
              </Switch>
            </Analytics>
          </Container>
        );
      }}
    </CacheBuster>
  );
};

export default withRouter(withPixelChainContract(App));
