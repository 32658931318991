import React from "react";
import styled from "styled-components";
import LogoNavbar from "components/Header/LogoNavbar";
import { srcLinks } from "utils/srcLinks";
import Footer from "components/Footer";
import { Link } from "react-router-dom";

const StyledMenu = styled.nav`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #1e2132;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 991px) {
    padding-top: 10rem;
    padding-bottom: 8rem;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const AnchorLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  height: 2.6rem;
  width: 14.4rem;
  margin: 0 0 3rem 0;
  font-weight: 500;

  background-color: ${({ to }) => (to !== "/profile" ? "#222429" : "#7634e2")};
  color: #ffffff;
  text-decoration: none;
`;

const TitleContainer = styled.div`
  display: flex;
  position: absolute;
  top: 1.6rem;
  left: 24px;
`;

const ContainerFooter = styled.div`
  align-items: center;
  color: #7d8da9;
  font-size: 11px;
  position: absolute;
  bottom: 1rem;

  div {
    padding-bottom: 0;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  height: 2.6rem;
  width: 14.4rem;
  margin: 0 0 3rem 0;
  font-weight: 500;

  background-color: #7634e2;
  color: #ffffff;
  text-decoration: none;
  border: none;
  cursor: pointer;
`;

const Menu = ({ open, setOpen, walletAddress, connectWallet }) => (
  <>
    <StyledMenu open={open}>
      <TitleContainer>
        <LogoNavbar open={open} />
      </TitleContainer>
      {srcLinks.map((src) => (
        <AnchorLink
          key={src.name}
          target={src.src !== "/profile" && "_blank"}
          to={src.src}
          onClick={() => setOpen(!open)}
        >
          {src.name}
        </AnchorLink>
      ))}
      {walletAddress === null ? (
        <Button
          onClick={() => {
            connectWallet();
          }}
        >
          Connect
        </Button>
      ) : (
        <AnchorLink to="/profile" onClick={() => setOpen(!open)}>
          Profile
        </AnchorLink>
      )}
      {/* <StatsNavBar /> */}
      <ContainerFooter>
        <Footer />
      </ContainerFooter>
    </StyledMenu>
  </>
);
export default Menu;
