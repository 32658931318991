import React from "react";
import styled from "styled-components";

const TabContainer = styled.div`
  padding: 1px 5px;
  display: flex;
  align-items: center;
  background: #393c53 none repeat scroll 0 0;
  overflow-x: hidden;
  border-radius: 5px;
`;

const TabName = styled.div`
  font-family: "Poppins", sans-serif;
  width: 150px;
  font-size: 14px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
`;

const CloseTabButton = styled.div`
  border-radius: 50%;
  height: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: #a9add3;
  cursor: pointer;
`;

export const Tab = ({ id, title, active, closable, quantity, onTabChange, onTabClose }) => {
  return (
    <TabContainer
      style={{ width: quantity === 1 ? "auto" : `calc(95% / ${quantity})` }}
      onClick={() => onTabChange(id)}
    >
      <TabName style={{ color: active ? "white" : "grey" }}>{title}</TabName>
      {closable && <CloseTabButton onClick={() => onTabClose(id)}>x</CloseTabButton>}
    </TabContainer>
  );
};

export const TabsBar = styled.div`
  width: 100%;
  height: 50px;
  background: #757892 none repeat scroll 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px 20px 0 0;
`;

const CreateTabButtonContainer = styled.div`
  min-height: 1.5rem;
  min-width: 1.5rem;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a9add3;
  cursor: pointer;
  background: #393c53 none repeat scroll 0 0;
`;

export const CreateTabButton = ({ onCreateTab }) => (
  <CreateTabButtonContainer onClick={onCreateTab}>+</CreateTabButtonContainer>
);
