import React from "react";
import styled from "styled-components";

const PreviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #aeaeae21 none repeat scroll 0 0;
  border-radius: 10px;
  padding: 10px 25px;
`;

const PreviewContainer = styled.div`
  align-items: center;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  justify-content: center;

  &:last-child {
    padding-bottom: 15px;
  }

  p {
    /* justify-self: flex-start; */
    margin: 10px;
    font-size: 2em;
    color: #fff;
    text-shadow: 5px 5px 3px #333;
  }
`;

export const Preview = ({ title, children }) => (
  <PreviewContainer>
    <p>{title}</p>
    {children}
  </PreviewContainer>
);

export const Previews = ({ children, className }) => (
  <PreviewsContainer className={className}>{children}</PreviewsContainer>
);
