import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "./PXC_logo_transparent_white_16x16.png";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 1rem;
`;

const Title = styled.h2`
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 1rem;
  color: #ffffff;
  font-weight: 600;
`;

const Image = styled.img`
  padding: 0px;
  height: 32px;
  width: 32px;
`;

const LogoNavbar = ({ open }) => (
  <StyledLink to={"/"} open={open}>
    <Image src={Logo} />
    <Title>PXC-256</Title>
  </StyledLink>
);
export default LogoNavbar;
