import React from "react";
import Media from "react-media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFillDrip, faPencilAlt, faUndo, faThLarge } from "@fortawesome/free-solid-svg-icons";
import uuid from "react-uuid";
import styled from "styled-components";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

import { ReactComponent as MirrorIcon } from "../../components/IconButton/mirror_pen.svg";
import { CreateTabButton, Tab, TabsBar } from "./Tab";
import { Previews, Preview } from "../../components/Previews";
import IconButton from "../../components/IconButton";
import CanvasEditorGrid from "../../components/CanvasEditorGrid/CanvasEditorGrid";
import Tools, { shortcuts as toolShortcuts } from "../../components/CanvasEditorGrid/Tools";
import { Tooltips } from "./Tooltips";

import withPixelChainContract from "../PixelChainContractWrapper";
import { SMALL_GRID, createGrid } from "../../utils/grid";
import debounce from "../../utils/debounce";
import { convertBigNumberToGrid, convertGridToBigNumber } from "utils/converters";

import Footer from "components/Footer";
import ErrorNote from "components/ErrorNote";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  background-color: #222429;
  border-radius: 20px;

  @media screen and (max-width: 1024px) {
    min-width: 90%;
    max-width: 90%;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  margin: 2rem 0;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
    margin: 0;
    align-items: center;
  }
`;

const Canvas = styled.canvas`
  border: 0.5px solid black;
`;

const LeftToolbarInsideBox = styled.div`
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  @media screen and (max-width: 1024px) {
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow-x: hidden;

    padding: 0 10px;
    background: #393c53 none repeat scroll 0 0;
  }

  @media screen and (max-width: 425px) {
    flex-direction: row;
    width: 100%;
    padding: 0 10px;
    overflow-x: hidden;

    background: #393c53 none repeat scroll 0 0;
  }
`;

const LeftToolbar = styled.div`
  display: flex;
  justify-self: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  margin-right: 30px;
  background: #393c53 none repeat scroll 0 0;
  border-radius: 0px 20px 20px 0px;
  justify-content: center;

  > p {
  }

  button {
    margin: 10px;
    font-size: 20px;
  }

  @media screen and (max-width: 1024px) {
    background-color: #212439;
    flex-direction: row;
    overflow-x: hidden;
    height: auto;
    border-radius: 0 0 20px 20px;
    box-sizing: border-box;
    padding: 0px;
    width: 100%;
    margin-right: 0px;

    > p {
      display: none;
    }
  }
`;

const RightToolbar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-right: 20px;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const StyledPreviews = styled(Previews)`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  overflow: auto;
  margin: 0 1rem;

  @media screen and (max-width: 1024px) {
    max-width: calc(100% - 30px);
  }
`;

const MintButton = styled.button`
  background-color: #7634e2;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  width: 70%;
  height: 50px;
  margin-top: 20px;

  font-size: 25px;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    width: 50%;
  }
`;

const UNDO_LIMIT = 30;
const initialUuid = uuid();
const initialTab = { id: initialUuid };

class Editor extends React.Component {
  state = {
    tool: 0,
    grid: createGrid(SMALL_GRID),
    gridBorder: true,
    activeTab: initialUuid,
    tabs: [initialTab],
    palette: ["white", "black"],
    window: { width: 0, heigth: 0 },
    canvasPixelSize: 0,
    callSetup: false,
    tokenExists: false,
  };

  shiftKey = false;
  ctrlKey = false;

  undoStack = [];

  debouncedGridChange = debounce(async (prevGrid, newGrid, pushToStack = true) => {
    if (pushToStack) {
      this.undoStack.push(prevGrid);
      if (this.undoStack.length > UNDO_LIMIT) this.undoStack.shift();
    }

    const newTokenId = convertGridToBigNumber(newGrid);

    this.setState({ grid: newGrid, tokenId: newTokenId, tokenExists: false }, this.saveActiveTabToLocalStorage);
  }, 100);

  componentDidMount() {
    this.setupCanvases();
    this.loadTabsFromLocalStorage(() => {
      const loaded = this.loadActiveTabFromLocalStorage();
      document.addEventListener("keydown", this.onKeyDown);
      document.addEventListener("keyup", this.onKeyUp);
      window.addEventListener("orientationchange", () => window.location.reload(false));

      if (!loaded) {
        this.saveActiveTabToLocalStorage();
        this.saveTabsToLocalStorage();
        this.drawPreviews(this.state.grid);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);
  }

  componentDidUpdate() {
    if (this.state.callSetup) {
      this.setupCanvases();
      this.setState({ callSetup: false });
    }
  }

  setupCanvases = () => {
    this.originalCanvasContext = this.originalCanvas.getContext("2d");
    this.scaledCanvas4Context = this.scaledCanvas4.getContext("2d");
    this.originalCanvasContext.setTransform(1, 0, 0, 1, 0, 0);
    this.scaledCanvas4Context.setTransform(4, 0, 0, 4, 0, 0);
    this.contexts = [this.originalCanvasContext, this.scaledCanvas4Context];
  };

  drawPreviews = (grid) => {
    this.contexts.forEach((canvasContext) => {
      canvasContext.clearRect(0, 0, SMALL_GRID, SMALL_GRID);
      for (let row = 0; row < grid.length; row++) {
        for (let column = 0; column < grid[row].length; column++) {
          canvasContext.fillStyle = this.state.palette[grid[row][column]];
          canvasContext.fillRect(column, row, 1, 1);
        }
      }
    });
  };

  loadActiveTabFromLocalStorage = () => {
    const savedState = localStorage.getItem(`t_${this.state.activeTab}`);

    if (!savedState) {
      this.clearAll();
      return false;
    }

    const parsedState = JSON.parse(savedState);

    this.setupCanvases();

    this.undoStack = parsedState.undoStack;

    this.setState(
      {
        tokenId: parsedState.tokenId,
        grid: convertBigNumberToGrid(parsedState.tokenId),
        callSetup: true,
      },
      () => this.drawPreviews(this.state.grid)
    );
    return true;
  };

  saveActiveTabToLocalStorage = () => {
    localStorage.setItem(
      `t_${this.state.activeTab}`,
      JSON.stringify({
        undoStack: this.undoStack,
        tokenId: convertGridToBigNumber(this.state.grid).toString(),
      })
    );
  };

  deleteTabFromLocalStorage = (id) => {
    localStorage.removeItem(`t_${id}`);
  };

  loadTabsFromLocalStorage = (callback = () => {}) => {
    const tabs = localStorage.getItem("tabs");
    const parsedTabs = tabs ? JSON.parse(tabs) : [initialTab];
    this.setState({ tabs: parsedTabs, activeTab: parsedTabs[0].id }, callback);
  };

  saveTabsToLocalStorage = () => {
    localStorage.setItem("tabs", JSON.stringify(this.state.tabs));
  };

  onGridChange = async (grid) => {
    this.drawPreviews(grid);
    this.debouncedGridChange(this.state.grid, grid);
  };

  onUndo = async () => {
    if (this.undoStack.length > 0) {
      const newGrid = this.undoStack.pop();
      this.drawPreviews(newGrid);
      this.debouncedGridChange(this.state.grid, newGrid, false);
    }
  };

  onToolChange = (tool) => this.setState({ tool });

  onToggleGridBorder = () => this.setState({ gridBorder: !this.state.gridBorder });

  clearAll = () => {
    this.undoStack = [];
    this.setState({ grid: createGrid(SMALL_GRID) }, () => {
      this.drawPreviews(this.state.grid);
    });
  };

  clearTab = () => {
    this.deleteTabFromLocalStorage(this.state.activeTab);
    this.clearAll();
    const tabs = localStorage.getItem("tabs");
    const parsedTabs = tabs ? JSON.parse(tabs) : this.state.tabs;
    const actualTab = parsedTabs.find((t) => t.id === this.state.activeTab);
    this.setState({ tabs: parsedTabs, activeTab: actualTab.id }, () => {
      this.saveTabsToLocalStorage();
      this.saveActiveTabToLocalStorage();
    });
  };

  onClearTab = (hideAlert) => {
    if (hideAlert !== true) {
      if (window.confirm("Are you sure you want to clear your workspace?")) {
        this.clearTab();
      }
    } else {
      this.clearTab();
    }
  };

  onKeyDown = (e) => {
    this.shiftKey = e.shiftKey;
    this.ctrlKey = e.ctrlKey;
  };

  onKeyUp = (e) => {
    this.shiftKey = e.shiftKey;
    this.ctrlKey = e.ctrlKey;
    this.handleShortcuts(e.code);
  };

  onTabChange = (id) => {
    if (id === this.state.activeTab) return;
    this.setState({ activeTab: id }, this.loadActiveTabFromLocalStorage);
  };

  onTabClose = (id) => {
    if (window.confirm("Are you sure you want to close this tab? All your work will be lost.")) {
      const newTabs = this.state.tabs.filter((tab) => tab.id !== id);
      this.setState({ tabs: newTabs, activeTab: newTabs[0].id }, () => {
        this.saveTabsToLocalStorage();
        this.deleteTabFromLocalStorage(id);
        this.loadActiveTabFromLocalStorage();
      });
    }
  };

  onTabCreate = () => {
    this.clearAll();
    const tabs = localStorage.getItem("tabs");
    const parsedTabs = tabs ? JSON.parse(tabs) : this.state.tabs;
    const newTabIndex = parsedTabs.length + 1;
    const newTab = { id: uuid(), name: `Canvas ${newTabIndex}` };
    parsedTabs.push(newTab);
    this.setState({ tabs: parsedTabs, activeTab: newTab.id }, () => {
      this.clearAll();
      this.saveTabsToLocalStorage();
      this.saveActiveTabToLocalStorage();
    });
  };

  handleShortcuts = (code) => {
    if (this.ctrlKey && code === "KeyZ") {
      this.onUndo();
    } else if (this.ctrlKey && toolShortcuts.ctrl[code] !== undefined) {
      this.onToolChange(toolShortcuts.ctrl[code]);
    } else if (this.ctrlShift && toolShortcuts.shift[code] !== undefined) {
      this.onToolChange(toolShortcuts.shift[code]);
    } else if (toolShortcuts.normal[code] !== undefined) {
      this.onToolChange(toolShortcuts.normal[code]);
    }
  };

  saveOnEthereum = async () => {
    const exists = await this.props.exists(this.state.tokenId);

    if (!exists) {
      return await this.props.createToken(this.state.tokenId);
    } else {
      this.setState({ tokenExists: exists });
    }
  };

  toolbar = () => (
    <LeftToolbar>
      <LeftToolbarInsideBox>
        <Tooltip placement="right" overlay={Tooltips.BUCKET}>
          <IconButton selected={this.state.tool === Tools.BUCKET} onClick={() => this.onToolChange(Tools.BUCKET)}>
            <FontAwesomeIcon icon={faFillDrip} size="lg" />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.PENCIL}>
          <IconButton selected={this.state.tool === Tools.PENCIL} onClick={() => this.onToolChange(Tools.PENCIL)}>
            <FontAwesomeIcon icon={faPencilAlt} size="lg" />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.MIRROR}>
          <IconButton selected={this.state.tool === Tools.MIRROR} onClick={() => this.onToolChange(Tools.MIRROR)}>
            <MirrorIcon />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.UNDO}>
          <IconButton onClick={this.onUndo}>
            <FontAwesomeIcon icon={faUndo} size="lg" />
          </IconButton>
        </Tooltip>

        <Tooltip placement="right" overlay={Tooltips.GRID}>
          <IconButton selected={this.state.gridBorder} onClick={this.onToggleGridBorder}>
            <FontAwesomeIcon icon={faThLarge} size="lg" />
          </IconButton>
        </Tooltip>
      </LeftToolbarInsideBox>
    </LeftToolbar>
  );

  render() {
    return (
      <>
        <MainContainer>
          {/* <Media query="(max-width: 1024px)">{(matches) => !matches && <StatsNavBar />}</Media> */}
          <EditorContainer>
            <TabsBar>
              <TabsContainer>
                {this.state.tabs.map((tab, i) => (
                  <Tab
                    key={tab.id}
                    id={tab.id}
                    closable={this.state.tabs.length > 1}
                    active={this.state.activeTab === tab.id}
                    quantity={this.state.tabs.length}
                    onTabChange={this.onTabChange}
                    onTabClose={this.onTabClose}
                    title={`Canvas ${i + 1}`}
                  />
                ))}
                <Media query="(max-width: 1024px)">
                  {(matches) =>
                    matches
                      ? this.state.tabs.length < 5 && <CreateTabButton onCreateTab={this.onTabCreate} />
                      : this.state.tabs.length < 10 && <CreateTabButton onCreateTab={this.onTabCreate} />
                  }
                </Media>
              </TabsContainer>
            </TabsBar>
            <Container>
              <Media query="(min-width: 1025px)" render={this.toolbar} />
              <GridContainer>
                <CanvasEditorGrid
                  grid={this.state.grid}
                  tool={this.state.tool}
                  onChange={this.onGridChange}
                  showGrid={this.state.gridBorder}
                />
                {!this.state.tokenExists && <MintButton style={{ backgroundColor: "#7b7b7b" }}>MINT CLOSED</MintButton>}
                <br></br>
                {this.state.tokenExists && <ErrorNote>Oops! This token already exists 😩</ErrorNote>}
              </GridContainer>
              <RightToolbar ref={this.rightToolbarDiv}>
                <StyledPreviews>
                  <Preview title="4:1">
                    <Canvas
                      width={`${SMALL_GRID * 4}px`}
                      height={`${SMALL_GRID * 4}px`}
                      ref={(ref) => (this.scaledCanvas4 = ref)}
                    />
                  </Preview>
                  <Preview title="1:1">
                    <Canvas
                      width={`${SMALL_GRID}px`}
                      height={`${SMALL_GRID}px`}
                      ref={(ref) => (this.originalCanvas = ref)}
                    />
                  </Preview>
                </StyledPreviews>
              </RightToolbar>
              <Media query="(max-width: 1024px)" render={this.toolbar} />
            </Container>
          </EditorContainer>
          <Footer />
        </MainContainer>
      </>
    );
  }
}

export default withPixelChainContract(Editor);
