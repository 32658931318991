import styled from "styled-components";

const IconButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  color: #212439;

  :active {
    color: #fff;
  }

  :hover {
    color: grey;
  }

  svg {
    height: 35px;
    width: 50px;

    path {
      fill: #212439;
      stroke: #212439;
      ${({ selected }) => (selected ? "fill: white; stroke: white;" : "")}
    }

    :active {
      path {
        fill: white;
        stroke: white;
      }
    }

    :hover {
      path {
        fill: grey;
        stroke: grey;
      }
    }

    @media screen and (max-width: 576px) {
      height: 2rem;
      width: 2rem;
    }
    @media screen and (max-width: 375px) {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  @media screen and (max-width: 991px) {
    font-size: 2vw;
    margin: 0px;
  }

  ${({ selected }) => (selected ? "color: white;" : "")}
`;

export default IconButton;
