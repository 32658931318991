import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 1rem;
  background: #f74949;
  border-radius: 10px;
  color: white;

  @media screen and (max-width: 1024px) {
    padding: 2rem 0;
  }
`;

const ErrorNote = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

export default ErrorNote;
