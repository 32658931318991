import Web3 from "web3";

export const convertGridToBigNumber = (grid) => {
  const number = new Web3.utils.BN(0);
  const bits = grid.reduce((acc, row) => {
    const dataRow = row.reduce((acc, n) => {
      acc.push(n);
      return acc;
    }, []);

    return acc.concat(dataRow);
  }, []);

  bits.reverse().forEach((n, i) => number.setn(i, n));

  return number;
};


export const convertBigNumberToGrid = (number) => {
  const bits = (new Web3.utils.BN(number)).toString(2, 256).split("").map((n) => parseInt(n, 10));
  const grid = [];

  for (let row = 0; row < 16; row++) {

    if (!grid[row]) {
      grid[row] = [];
    }

    for (let col = 0; col < 16; col++) {
      grid[row][col] = bits[(row * 16) + col];
    }
  }

  return grid;
};
