import { abi } from "./abi";
import { CONTRACT_ADDRESSES } from "./constants";

const createToken = async (web3, tokenId) => {
  const networkId = await web3.eth.net.getId();
  const contract = new web3.eth.Contract(abi, CONTRACT_ADDRESSES[networkId]);

  const accounts = await web3.eth.getAccounts();

  let gasLimit = null;

  try {
    gasLimit = await contract.methods.mint(tokenId).estimateGas({ from: accounts[0] });
  } catch (error) {}

  return () => contract.methods.mint(tokenId).send({ from: accounts[0], gasLimit: gasLimit });
};

const exists = async (web3, tokenId) => {
  const networkId = await web3.eth.net.getId();
  const contract = new web3.eth.Contract(abi, CONTRACT_ADDRESSES[networkId]);
  return await contract.methods.ownerOf(tokenId).call();
};

export default { createToken, exists };
