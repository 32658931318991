import styled from "styled-components";

const AnchorStyled = styled.a`
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ children }) => (children.includes("0x") ? "auto" : "pointer")};
  font-size: 16px;
  height: 32px;
  width: 173px;
  background-color: ${({ children }) =>
    children === "Connect" ? "#7634e2" : children.includes("0x") ? "#7634e2" : "#222429"};
  border-radius: 1rem;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s;

  &:hover {
    transform: ${({ children }) => (children.includes("0x") ? "scale(1)" : "scale(1.05)")};
  }
`;

const ButtonNavBar = ({ children, href, target, connectWallet, profile }) => (
  <AnchorStyled href={href} target={target} onClick={connectWallet || profile}>
    {children}
  </AnchorStyled>
);

export default ButtonNavBar;
