import styled from "styled-components";
import ButtonNavBar from "../ButtonNavBar";
import { srcLinks } from "utils/srcLinks";
import LogoNavbar from "./LogoNavbar";
import { shortenWalletAddress } from "utils/walletHelper";

const ContainerButtonsNav = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3rem;
`;
const ContainersButtonsNav = ({ walletAddress, connectWallet, history }) => (
  <>
    <ContainerButtonsNav>
      <ButtonNavBar href={srcLinks[0].src} target={"_blank"}>
        {srcLinks[0].name}
      </ButtonNavBar>
      <ButtonNavBar href={srcLinks[1].src} target={"_blank"}>
        {srcLinks[1].name}
      </ButtonNavBar>
    </ContainerButtonsNav>
    <LogoNavbar />
    <ContainerButtonsNav>
      <ButtonNavBar href={srcLinks[2].src} target={"_blank"}>
        {srcLinks[2].name}
      </ButtonNavBar>
      {walletAddress === null ? (
        <ButtonNavBar connectWallet={connectWallet}>Connect</ButtonNavBar>
      ) : (
        // <ButtonNavBar profile={() => history.push("/profile")}>Profile</ButtonNavBar>
        <ButtonNavBar>{shortenWalletAddress(walletAddress)}</ButtonNavBar>
      )}
    </ContainerButtonsNav>
  </>
);

export default ContainersButtonsNav;
