import styled from "styled-components";
import Logo from "./FooterLogo.png";

const ImageContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 1rem 0;

  @media screen and (max-width: 1024px) {
    padding: 2rem 0;
  }
`;

const ImageLogo = styled.img`
  padding: 0px;
  height: 44px;
  width: 223px;
`;

const LogoAnchor = styled.a`
  text-decoration: none;
`;

const Footer = () => {
  return (
    <ImageContainer>
      <LogoAnchor href="https://nftstudios.io/" target="_blank">
        <ImageLogo src={Logo} />
      </LogoAnchor>
    </ImageContainer>
  );
};

export default Footer;
