import { useState, useRef } from "react";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Burger from "../Burger/Burger";
import Menu from "../Menu/Menu";
import LogoNavbar from "./LogoNavbar";
import ContainersButtonsNav from "./ContainersButtonsNav";

const HeaderStyled = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 1.3rem;
`;

const Header = ({ history, location, connectWallet, walletAddress }) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  return (
    <HeaderStyled>
      <Media query="(max-width: 1024px)">
        {(matches) =>
          matches ? (
            <div ref={node}>
              <Burger open={open} setOpen={setOpen} />
              <Menu open={open} setOpen={setOpen} walletAddress={walletAddress} connectWallet={connectWallet} />
              <LogoNavbar open={open} />
            </div>
          ) : (
            <ContainersButtonsNav walletAddress={walletAddress} connectWallet={connectWallet} history={history} />
          )
        }
      </Media>
    </HeaderStyled>
  );
};

export default withRouter(Header);
